import { LoginModule } from './../../login/login.module';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
  ViewEncapsulation,
  AfterViewInit
} from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs/Subscription';
import { Router, NavigationEnd } from '@angular/router';
import { PerfilDataService } from '../../../service/perfil/perfil.service';
import { SessionService } from '../../../service/session/session.service';
import { UserService } from '../../../service/user-service/user.service';
import { AlertasService } from '../../../service/alertas/alertas.service';
import { TranslateService } from '@ngx-translate/core';
import swal from 'sweetalert2';
import { getSwal } from '../../../utils/swalObject';
import { Usuario } from '../../../model/usuario';
import { environment } from '../../../../environments/environment';
import moment from 'moment';
import { AmbienteService } from 'src/app/service/ambiente/ambiente.service';
import { ManualService } from 'src/app/service/manual/manual.service';
import * as FileSaver from 'file-saver';
import { ActivatedRoute } from '@angular/router';
import { CadastroService } from 'src/app/service/cadastro/cadastro.service';
import { faUser } from '@fortawesome/free-solid-svg-icons';

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[app-header]',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  // tslint:disable-next-line:use-host-property-decorator
  host: { class: 'headerBox' },
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('300ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('400ms ease-in', style({ transform: 'translateX(100%)' }))
      ])
    ])
  ]
})
export class HeaderComponent implements OnInit, AfterViewInit {
  urlLogo: string;

  @Input() hasSession: boolean;
  @Input() subItem: number;
  @Output() onSidebarToggle = new EventEmitter<boolean>();
  subscription: Subscription;
  faUser = faUser;
  user = false;
  userImg: SafeUrl;
  clientImg: SafeUrl;
  userData: Object = null;
  hasImg: boolean = false;
  hasClientImg: boolean = false;
  exibirAlertas: boolean = false;
  labels: any;
  trocaProcuracao: boolean = false;
  url: string = environment.BASE_URL.replace(/^http(s?)/i, '');
  token: string = null;
  firstVisit: any;
  alertas: Array<any> = [];
  watchAlerts: Subscription;
  possuiFilial;
  imgLogo: SafeUrl;
  imgLogoDTE: SafeUrl;
  imgLogoAmbiente: SafeUrl;
  dadosCliente: any = {};
  mostrarBotao: boolean = true;
  mostrarBotaoLogout: boolean = false;
  codigoCliente: any = null;
  routeSub: Subscription;
  idPerfilAcesso: any;
  nomeManualContribuinte: any;
  mostrarButton: boolean = true;
  mostrarOutroBotao = false;

  constructor(
    private _sanitizer: DomSanitizer,
    private router: Router,
    private domSanitizer: DomSanitizer,
    private userService: UserService,
    private alertasService: AlertasService,
    private translate: TranslateService,
    private sessionService: SessionService,
    private perfilService: PerfilDataService,
    private ambienteService: AmbienteService,
    private manualService: ManualService,
    private route: ActivatedRoute,
    private cadastroService: CadastroService
  ) {
    this.token = window.localStorage.getItem('token');

    this.router.events
      .filter(event => event instanceof NavigationEnd)
      .subscribe(async (event: NavigationEnd) => {
        this.firstVisit = window.localStorage.getItem('firstVisit');

        const mostrarBotaoLogout = [
          '/',
          '/perfis',
          '/cep-residencial',
          '/cep-comercial',
          '/index',
          '/termo-adesao',
          '/procuracoes',
          '/cadastro/contribuinte/dados',
          '/cadastro/contribuinte/representante'
        ];

        if (mostrarBotaoLogout.includes(event.url)) {
          if (

            event.url === '/cep-residencial' ||
            event.url === '/cep-comercial' ||
            event.url === '/termo-adesao' ||
            event.url === '/procuracoes' ||
            event.url === '/cadastro/contribuinte/dados' ||
            event.url === '/cadastro/contribuinte/representante' ||
            event.url === '/') {
            this.mostrarBotaoLogout = false;
            this.mostrarBotao = false;
          } if (event.url === '/perfis') {
            this.mostrarBotaoLogout = true;
            this.mostrarBotao = false;
          } else if (event.url === '/' || event.url === '/index') {
            this.mostrarBotaoLogout = false;
            this.mostrarBotao = true;
          }
        }
      });


    this.hasImg = false;
    this.hasClientImg = false;
    this.setLabels();
    this.routeSub = this.route.params.subscribe(params => {
      if (params['id']) {
        this.idPerfilAcesso = params['id'];
      }
    });
  }

  ngOnInit() {
    let filial = parseInt(window.localStorage.getItem('PossuiFilial'));
    if (filial >= 2) {
      this.possuiFilial = true;
    } else {
      this.possuiFilial = false;
    }
    this.router.events
      .filter(event => event instanceof NavigationEnd)
      .subscribe(async (event: NavigationEnd) => {
        this.token = window.localStorage.getItem('token');

        if (this.token && event.url !== '/index') {
          if (event.url === '/home') {
            this.alertasService.getNaoLidos(this.token).subscribe((res: any) => {
              this.alertas = res.resultado;
              this.fixDescriptions();
            });
          }
          if (event.url.includes('/caixa-postal/mensagem/')) {
            await new Promise(f => setTimeout(f, 500));
            this.alertasService.getNaoLidos(this.token).subscribe((res: any) => {
              this.alertas = res.resultado;
              this.fixDescriptions();
            });
          }
        }
      });

    this.verifySession(this.sessionService.getSession());
    this.subscription = this.userService.getInfo().subscribe(data => {
      this.verifySession(data);
    });

    this.watchAlerts = this.alertasService.getUpdates().subscribe(data => {
      this.alertas = [];
    });

    this.obterCliente();
  }

  ngAfterViewInit() {
    // this.obterLogo();
  }

  redirecionarGovBr() {
    let dev = 'https%3A%2F%2Fdtesantoandre%2Edev%2Egiss%2Ecom%2Ebr/';
    // https://sso.staging.acesso.gov.br
    // dtesantoandre.dev.giss.com.br

    // let prod = 'https%3A%2F%2Fdtesantoandre%2Egiss%2Ecom%2Ebr/'
    let nonce = (Math.random() + 1).toString(36).substring(7);
    let state = (Math.random() + 1).toString(36).substring(7);
    let urlIntegracao = "https://sso.staging.acesso.gov.br/authorize?response_type=code&client_id=dtesantoandre.dev.giss.com.br&scope=openid+email+phone+profile+govbr_empresa+govbr_confiabilidades&redirect_uri=" + dev + "&nonce=" + nonce + "&state=" + state;
    window.location.href = urlIntegracao;
  }

  LogoutGov() {
    localStorage.removeItem('perfil');
    localStorage.removeItem('dadosGov');
    localStorage.removeItem('listaCnpjVinculado');
    localStorage.removeItem('procurador');
    localStorage.removeItem('credencialIntegracao');
    localStorage.removeItem('contribuinteEmUso');
    localStorage.removeItem('firstVisit');
    localStorage.removeItem('token');
    localStorage.removeItem('tokenCbo');
    localStorage.removeItem('data');
    localStorage.removeItem('tipo');
    localStorage.removeItem('codCliente');
    localStorage.removeItem('session');

    window.location.href = "https://sso.acesso.gov.br/logout";

    if (window.location.href == "https://sso.acesso.gov.br/logout") {
      this.router.navigate(["/index"]);
      window.location.href = "https://dtesantoandre.giss.com.br";
    }

    this.router.navigateByUrl('/index').then(() => {
      window.location.reload();
    });
  }

  fixDescriptions() {
    this.alertas.map(alerta => {
      if (alerta.tipoAlerta.descricao.length > 37) {
        alerta.tipoAlerta['desc'] =
          moment(alerta.dataCriacao).format('DD/MM/YYYY HH:mm') +
          ' - ' +
          alerta.tipoAlerta.descricao.substring(0, 37) +
          '...';
      } else {
        alerta.tipoAlerta['desc'] =
          moment(alerta.dataCriacao).format('DD/MM/YYYY HH:mm') +
          ' - ' +
          alerta.tipoAlerta.descricao;
      }
    });
  }

  verifySession(data) {
    this.userData = data;
    if (data.usuario) {
      if (data.usuario.tipoIdentificacao == 1) {
        this.labels.cpfCnpf = 'CPF';
        this.labels.nomeRazaoSocial = 'Nome';
        this.labels.inscricao = '';
      } else {
        this.labels.cpfCnpf = 'CNPJ';
        this.labels.nomeRazaoSocial = 'Razão Social';
        this.labels.inscricao = this.translate.instant('GLOBAIS.INSCRICAO');
      }
    }

    if (window.localStorage.getItem('hasletters') === 'true') {
      this.trocaProcuracao = true;
    } else {
      this.trocaProcuracao = this.possuiFilial;
    }

    if (data.usuario && data.usuario.imagem) {
      this.hasImg = true;
      this.userImg = this.domSanitizer.bypassSecurityTrustUrl(
        data['usuario'].imagem.toString()
      );
    } else {
      this.hasImg = false;
      data.loggedIn ? this.ongetUserImage() : console.log(data);
    }

    if (data.loggedIn === true) {
      this.hasClientImg = true;
      this.obterImagem();
      // this.obterLogo();
    } else {
      this.hasClientImg = false;
    }
  }

  obterImagem() {
    this.perfilService.obterImagem().subscribe(
      res => {
        this.clientImg = 'data:image/jpeg;base64,' + res.resultado;
      }, err => {
        console.log(err.error.text);
      });
  }

  obterLogo() {
    this.dadosCliente = JSON.parse(localStorage.getItem('session'));
    this.codigoCliente = this.dadosCliente.cliente.codigo;

    this.ambienteService.obterDadosAmbiente(this.codigoCliente).subscribe(
      res => {
        this.imgLogo = 'data:image/jpeg;base64,' + res.resultado.configuracao.logoDte;
      }
    )
  }

  ongetUserImage() {
    return new Promise((resolve, reject) => {
      this.perfilService.getUserImage().subscribe(
        image => {
          if (image !== null) {
            this.getProcessedImageBlob(image).then(img => {
              resolve(img);
              this.hasImg = true;
              this.userImg = this.domSanitizer.bypassSecurityTrustUrl(
                img.toString()
              );
            });
          } else {
            this.hasImg = false;
            resolve(null);
          }
        },
        error => {
          resolve(null);
        }
      );
    });
  }

  getProcessedImageBlob(imageBlob) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.addEventListener(
        'load',
        () => {
          resolve(reader.result.toString());
        },
        false
      );
      reader.addEventListener(
        'error',
        () => {
          console.log('error');
        },
        false
      );

      if (imageBlob) {
        reader.readAsDataURL(imageBlob);
      }
    });
  }

  redirect() {
    if (this.userData['loggedIn']) {
      this.router.navigate(['home']);
    } else {
      this.userService.sendInfo({ loggedIn: false });
      this.sessionService.clearSession();
      this.router.navigate(['index']);
    }
  }

  clickUsuario() {
    this.user = !this.user;
  }

  trocarEmpresa() {
    swal.fire(
      getSwal(
        'warning',
        'Alterar Contribunte',
        'Deseja acessar com outro contribuinte?',
        'sim',
        'não'
      )
    ).then(res => {
      if (res.value) {
        const newUserData = Object.assign({}, this.userData);
        newUserData['loggedIn'] = false;
        this.userService.sendInfo({ ...newUserData });
        if (newUserData['perfilAcesso'].codigo == 2) {
          setTimeout(() => {
            this.router.navigate(['/certificado']);
          }, 150);
        } else {
          setTimeout(() => {
            this.router.navigate(['/procuracoes']);
          }, 150);
        }
      }
    });
  }

  onSignOut(val: boolean) {
    this.user = val;
  }

  obterCliente() {
    this.cadastroService.clientes().subscribe(
      res => {
        console.log("res", res)
        this.idPerfilAcesso = res.resultado['0'].codigo
        this.imgLogoAmbiente = 'data:image/jpeg;base64,' + res.resultado['0'].configuracao.logo
        this.imgLogoDTE = 'data:image/jpeg;base64,' + res.resultado['0'].configuracao.logoDte
      },
    );
  }

  baixarManual() {
    this.manualService.baixarManual(this.idPerfilAcesso).subscribe(
      (res: any) => {
        const blob = new Blob([res], { type: `application/pdf}` });
        FileSaver.saveAs(blob, `manualContribuinte.pdf`);
      }, error => {
        swal.fire(
          getSwal(
            'error',
            'Ops!',
            'Manual não foi definido',
            'ok',
            null
          )
        )
      })
  }

  setLabels() {
    this.labels = {
      logo: this.translate.instant('GLOBAIS.LOGO'),
      brasao: this.translate.instant('GLOBAIS.BRASAO'),
      titulo: this.translate.instant('GLOBAIS.TITULO')
    };
  }
}

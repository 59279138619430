import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from "rxjs/Observable";
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from "../../../environments/environment";
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/forkJoin';
import { IntegracaoService } from "../../service/Integracao/integracao.service";
import swal from 'sweetalert2';
import { getSwal } from "../../utils/swalObject";
import { UserService } from "../../service/user-service/user.service";
import { LoginService } from "../../service/login/login.service";
import { FormGroup } from "@angular/forms";
import { SessionService } from "../../service/session/session.service";
import { CadastroService } from 'src/app/service/cadastro/cadastro.service';
import { ConsultaUsuarioService } from 'src/app/service/consulta-usuario/consultar.user.service';


@Component({
  selector: 'app-integracao',
  templateUrl: './integracao.component.html',
  styleUrls: ['./integracao.component.scss']
})
export class IntegracaoComponent implements OnInit {

  code: string;
  state: string;
  loading: boolean = true;
  url: string = environment.BASE_URL;
  currentCnpj: string;
  loginForm: FormGroup;
  errorMsg: string = null;
  hasError = false;
  semCadastroDte: boolean = false;
  codTermoAdesao: any;
  dadosCliente: any;
  codUsuario: any;
  data: any;
  nivelBronze = false;
  dadosIntegracao: any;
  dadosFormatados: any;
  listaContribuintes: any = [];

  constructor(private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private integracaoService: IntegracaoService,
    private userService: UserService,
    private loginService: LoginService,
    private sessionService: SessionService,
    private consultaUsuario: ConsultaUsuarioService,
    private cadastroService: CadastroService,
  ) {
    console.log('Iniciando Integração GOV.BR');

    router.events.subscribe((route: any) => {
      this.code = window.location.href.toString().split('/')[5];
      ;
      this.state = window.location.href.toString().split('/')[6];
      ;
    });
  }

  ngOnInit() {
    this.validarIntegracao();
    this.obterTermoAdesao();
  }

  validaTermoConfigurado() {
    swal.fire({
      title: 'Atenção',
      html: 'Termo de Adesão não configurado. Por favor entre em contato com o administrador.',
      icon: 'warning',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'OK',
      backdrop: false,
      allowOutsideClick: false
    }).then((result) => {
      if (result.value) {
        localStorage.removeItem('perfil');
        localStorage.removeItem('listaCnpjVinculado');
        localStorage.removeItem('dadosGov');
        localStorage.removeItem('procurador');
        localStorage.removeItem('credencialIntegracao');
        localStorage.removeItem('contribuinteEmUso');
        localStorage.removeItem('firstVisit');
        localStorage.removeItem('token');
        localStorage.removeItem('tokenCbo');
        localStorage.removeItem('data');
        localStorage.removeItem('tipo');
        localStorage.removeItem('codCliente');
        localStorage.removeItem('session');

        window.location.href = "https://sso.acesso.gov.br/logout";

        if (window.location.href == "https://sso.acesso.gov.br/logout") {
          this.router.navigate(["/index"]);
          window.location.href = "https://dtesantoandre.giss.com.br";
        }

        this.router.navigateByUrl('/index').then(() => {
          window.location.reload();
        });
      }
    });
  }

  obterTermoAdesao() {
    this.cadastroService.clientes().subscribe(
      res => {
        console.log("res", res)
        if (res.resultado[0].configuracao.termoAdesao != null) {
          this.codTermoAdesao = res.resultado['0'].configuracao.termoAdesao.codigo;
          return;
        }
        else {
          this.validaTermoConfigurado();
          return;
        }
      },
    );
  }

  validarIntegracao() {
    this.cadastroService.clientes().subscribe(
      res => {
        console.log("res", res)
        if (res.resultado[0].configuracao.termoAdesao != null) {
          this.codTermoAdesao = res.resultado['0'].configuracao.termoAdesao.codigo;
          return;
        }
        else {
          this.validaTermoConfigurado();
          return;
        }
      },
    );
    let urlParametros = localStorage.getItem('credencialIntegracao');
    let objParametros = JSON.parse(urlParametros);
    if (objParametros) {
      this.code = objParametros.code;
      this.state = objParametros.state;
      this.integracaoService.integracaoGovBr(this.code, this.state)
        .subscribe(
          res => {
            if (res.resultado.usuarioGov.empresasCertificadoDigital != null) {
              localStorage.setItem('listaCnpjVinculado', JSON.stringify(res.resultado));
            }
            if (res.resultado.cadastradoDte == false && res.resultado.usuario == null) {
              this.semCadastroDte = true;
              localStorage.setItem('dadosGov', JSON.stringify(res.resultado));
            }
            localStorage.setItem('token', res.resultado.token);
            this.dadosCliente = localStorage.setItem('objUsuario', res);
            let niveisUsuario = res.resultado.usuarioGov.niveisUsuario;
            let mostrarMensagem = true;
            this.nivelBronze = false;
            for (let i = 0; i < niveisUsuario.length; i++) {
              let id = niveisUsuario[i].id;
              if (id == 2 || id == 3) {
                mostrarMensagem = false;
                this.nivelBronze = false;
                break;
              }
            }
            // if (mostrarMensagem) {
            //   swal.fire({
            //     title: 'Atenção',
            //     html: 'O acesso à plataforma é permitido apenas para nível Prata ou Ouro. Para saber como elevar seu nível de acesso: <a style="cursor: pointer; color: blue; text-decoration: underline;" href="https://www.gov.br/governodigital/pt-br/identidade/conta-gov-br/niveis-da-conta-govbr">https://www.gov.br/governodigital/pt-br/identidade/conta-gov-br/niveis-da-conta-govbr</a>',
            //     icon: 'warning',
            //     confirmButtonColor: '#3085d6',
            //     confirmButtonText: 'OK',
            //     allowOutsideClick: false
            //   }).then((result) => {
            //     if (result.value) {
            //       this.router.navigate(['/index']);

            //       localStorage.removeItem('perfil');
            //       localStorage.removeItem('dadosGov');
            //       localStorage.removeItem('procurador');
            //       localStorage.removeItem('listaCnpjVinculado');
            //       localStorage.removeItem('credencialIntegracao');
            //       localStorage.removeItem('contribuinteEmUso');
            //       localStorage.removeItem('firstVisit');
            //       localStorage.removeItem('token');
            //       localStorage.removeItem('tokenCbo');
            //       localStorage.removeItem('data');
            //       localStorage.removeItem('tipo');
            //       localStorage.removeItem('codCliente');
            //       localStorage.removeItem('session');

            //       window.location.href = "https://sso.acesso.gov.br/logout";

            //       if (window.location.href == "https://sso.acesso.gov.br/logout") {
            //         this.router.navigate(["/index"]);
            //         window.location.href = "https://dtesantoandre.giss.com.br";
            //       }

            //       this.router.navigateByUrl('/index').then(() => {
            //         window.location.reload();
            //       });
            //     }
            //   })
            // }
            this.loading = false;
            this.handleResponse(res);
          },
          error => {
            this.handleErrors("Houve um erro na validação dos dados para integração do GOV.BR")
          }
        );
    };
  }

  handleErrors(error) {
    swal.fire(
      getSwal(
        'warning',
        'Erro na Integração GOV.BR',
        error,
        'ok',
        null,
      ),
    ).then(res => {
      this.loading = false;
      localStorage.removeItem('perfil');
      localStorage.removeItem('dadosGov');
      localStorage.removeItem('procurador');
      localStorage.removeItem('listaCnpjVinculado');
      localStorage.removeItem('credencialIntegracao');
      localStorage.removeItem('contribuinteEmUso');
      localStorage.removeItem('firstVisit');
      localStorage.removeItem('token');
      localStorage.removeItem('tokenCbo');
      localStorage.removeItem('data');
      localStorage.removeItem('tipo');
      localStorage.removeItem('codCliente');
      localStorage.removeItem('session');

      window.location.href = "https://sso.acesso.gov.br/logout";

      if (window.location.href == "https://sso.acesso.gov.br/logout") {
        this.router.navigate(["/index"]);
        window.location.href = "https://dtesantoandre.giss.com.br";
      }

      this.router.navigateByUrl('/index').then(() => {
        window.location.reload();
      });
    });
  }


  handleResponse(res) {
    this.loading = false;
    const userInfo = res.resultado;

    this.currentCnpj = (res.resultado.usuario && res.resultado.usuario.cpfCnpj) ||
      (res.resultado.usuarioGov && res.resultado.usuarioGov.cpf);

    if (this.currentCnpj.length >= 15) {
      console.warn();
      if (res.resultado.empresas !== null) {
        this.userService.setCompanysAvailable(res.resultado.empresas);
        this.router.navigate(['/filiais']);
        this.userService.sendInfo({
          loggedIn: false,
          ...res['resultado'].empresas
        });
      } else {
        this.userService.sendInfo({ loggedIn: false, ...res.resultado });
        this.loginConfigs(res.resultado);
      }
    } else {
      this.userService.sendInfo({ loggedIn: false, ...res.resultado });
      this.loginConfigs(res.resultado);
    }
  }

  loginConfigs(obj) {
    if (this.nivelBronze) {
      return;
    }

    if (this.semCadastroDte) {
      this.router.navigate(['perfis']);
      return;
    }

    if (this.listaContribuintes.length == 1 && !this.dadosFormatados.sessao.contribuinte) {
      localStorage.setItem('cadastroEmUso', JSON.stringify(this.listaContribuintes[0]));
      this.router.navigate(['/perfis']);
    }
    localStorage.setItem('firstVisit', 'true');
    this.loginService.clientes().subscribe(
      (res: any) => {
        console.log('loginconfigs >> ', res.resultado);
        this.data = res.resultado;
        this.codUsuario = res.resultado[0].usuario.codigo;

        return new Promise((resolve, reject) => {
          this.consultaUsuario.validarAdesaoAtiva(this.codUsuario, this.codTermoAdesao).subscribe(
            (res: any) => {
              if (res) {
                resolve(false);
                localStorage.setItem('codCliente', JSON.stringify(this.data));
                let codCliente = JSON.parse(localStorage.getItem('codCliente'));
                console.log(codCliente)
                this.router.navigate(['perfis']);
              } else {
                if (this.data.length <= 1) {
                  console.log('data', this.data)
                  let codCliente = this.data[0].codigo;
                  this.loginService
                    .obterSessao(codCliente)
                    .then(data => {
                      if (data['resultado'].perfilAcesso.codigo !== 3) {
                      } else {
                        this.userService.sendInfo({
                          loggedIn: false,
                          ...data['resultado']
                        });
                        this.router.navigate(['/procuracoes']);
                      }
                    })
                    .catch(error => {
                      this.handleError(error);
                    });
                } else {
                  localStorage.setItem('codCliente', JSON.stringify(this.data));
                  let codCliente = JSON.parse(localStorage.getItem('codCliente'));
                  console.log(codCliente)
                  this.router.navigate(['perfis']);
                }
              }
            })
        });
      },
      error => this.handleError(error)
    );
  }

  handleError(err) {
    const error = err['error'];
    this.loading = false;
    this.hasError = true;
    this.errorMsg = error.excecao.recomendacao;

    swal.fire(
      getSwal(
        error.excecao.codigo === 'E000' ? 'warning' : 'error',
        ``,
        error.excecao.recomendacao,
        'ok',
        null
      )
    ).then(res => {
      this.loginForm.reset();
      this.userService.sendInfo({
        loggedIn: false
      });
      this.sessionService.clearSession();
      this.router.navigate(['index']);
    });
  }

}